@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500");
@import "./sass/global-sass";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
body {
  &.collapsed {
    .mat-tooltip {
      display: block;
    }

    .content-cover {
      .sidebar {
        width: 64px !important;

        @media screen and (max-width: 1600px) {
          width: 55px !important;
        }

        @media screen and (max-width: 568px) {
          width: 0px !important;
        }

        .page-sidebar {
          width: 64px !important;

          @media screen and (max-width: 1600px) {
            width: 55px !important;
          }

          @media screen and (max-width: 568px) {
            width: 0px !important;
          }
        }
      }

      .main-content {
        width: calc(100% - 64px) !important;

        @media screen and (max-width: 1600px) {
          width: calc(100% - 55px) !important;
        }

        @media screen and (max-width: 992px) {
          margin-left: 55px !important;
        }

        @media screen and (max-width: 568px) {
          width: 100% !important;
          margin-left: 0px !important;
        }
      }
    }
  }
}

.cdk-overlay-pane {
  &.cropper-modal {
    max-width: 95% !important;

    .mat-dialog-container {
      padding: 0px;
    }
  }

  &.confirmation-modal {
    @media screen and (max-width: 568px) {
      width: 90vw !important;
      max-width: 90vw !important;
    }

    .mat-dialog-container {
      padding: 0px;
    }
  }
}

.hide-checkbox {
  display: none;

  .mat-option-pseudo-checkbox {
    display: none !important;
  }
}

.link {
  color: #089e8f;
  cursor: pointer;
}

.table-header {
  .mat-sort-header-arrow {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  &:hover {
    .mat-sort-header-arrow {
      color: #089e8f !important;
    }
  }
}

[aria-sort="ascending"],
[aria-sort="descending"] {
  .mat-sort-header-arrow {
    color: #089e8f;
  }
}

button:focus {
  outline: none;
}

.mat-expansion-panel-header {
  .mat-content {
    flex: none;
  }
}

.cursor-pointer {
  cursor: pointer;
  outline: none;
}

.margin-10px {
  margin: 10px;
}

.padding-10px {
  padding: 10px;
}

.button-row {
  padding: 20px 0px 0px;
  display: flex;
  justify-content: flex-end;
}
.ck-editor__editable_inline {
  min-height: 300px !important;
}
.margin-tb-10 {
  margin: 10px 0px 10px 0px;
}




body{
overflow-y: hidden;
}