mat-form-field {
    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }

    &.mat-focused {
        .mat-form-field-flex {
            .mat-form-field-outline-thick {
                div {
                    border-color: $theme-color;
                }
            }
        }

        .mat-form-field-infix {
            .mat-input-element {
                font-size: 15px;
            }

            .mat-form-field-label-wrapper {
                mat-label {
                    color: $theme-color;
                }

                label {
                    span {
                        color: $theme-color;
                    }
                }
            }
        }

        .mat-form-field-ripple {
            background-color: $theme-color !important;
        }
    }
    &.percentage-input {
        .mat-form-field-suffix {
            top: 0px;
        }
    }
    &.date-input {
        .mat-form-field-suffix {
            top: 6px;
            right: -9px;
        }
    }
}

.mat-option {
    &.mat-selected {
        .mat-option-pseudo-checkbox {
            &.mat-pseudo-checkbox-checked {
                background: $theme-color;
            }
        }
        .mat-option-text {
            color: $theme-color;
        }
    }
    .mat-option-text {
        font-size: 13px;
    }
}

.mat-checkbox-checked,
.mat-checkbox-indeterminate {
    &.mat-accent {
        .mat-checkbox-background {
            background-color: $theme-color !important;
        }
    }
}

.mat-form-field-infix {
    .mat-input-element {
        min-height: 21px;
        line-height: 21px;
        font-size: 15px;
        &:disabled {
            color: rgba(0, 0, 0, 0.8);
        }
    }

    .mat-select-value {
        line-height: 21px;
    }

    .mat-select-disabled {
        span {
            color: rgba(0, 0, 0, 0.8);
        }
    }
}

.mat-tab-group {
    .mat-tab-label-container {
        .mat-tab-labels {
            .mat-tab-label {
                opacity: 1;
            }

            .mat-ink-bar {
                background: $theme-color;
            }
        }
    }

    &.mat-primary {
        .mat-ink-bar {
            background: $theme-color;
        }
    }
}

.mat-radio-button {
    .mat-radio-label-content {
        font-size: 15px;
    }
    &.mat-radio-checked {
        .mat-radio-label {
            .mat-radio-container {
                .mat-radio-outer-circle {
                    border-color: $theme-color;
                }
                .mat-radio-inner-circle {
                    background-color: $theme-color;
                }
            }
        }
    }
    &.mat-accent {
        .mat-radio-ripple {
            .mat-ripple-element {
                &:not(.mat-radio-persistent-ripple) {
                    background-color: $theme-color;
                }
            }
        }
    }
}

.mat-sort-header-container {
    justify-content: center !important;
}

.mat-select-placeholder {
    color: rgba(0, 0, 0, 0.8);
}

.mat-form-field-label {
    color: rgba(0, 0, 0, 0.8);
}

.mat-menu-content {
    padding: 5px 15px 15px;
}

.mat-toolbar-multiple-rows {
    height: 64px;
    @media screen and (max-width: 1600px) {
        height: 50px;
        min-height: 50px !important;
    }
}

.mat-list-item-content {
    @media screen and (max-width: 1600px) {
        padding: 0px 12px !important;
    }
}

.mat-snack-bar-container {
    height: 100%;
}

.mat-input-element {
    &.mat-form-field-autofill-control {
        text-overflow: ellipsis;
    }
}

// height
.mat-form-field-appearance-outline {
    .mat-form-field-infix {
        padding: 12px 0 12px 0;
    }

    .mat-form-field-outline {
        top: 15px !important;
    }

    .mat-select-arrow-wrapper {
        transform: none !important;
    }

    mat-label {
        font-size: 14px !important;
    }

    .mat-select-value {
        span {
            font-size: 14px !important;
        }
    }

    .mat-form-field-subscript-wrapper {
        padding: 0 10px 0px 0px !important;
        margin-top: 2px;
        position: inherit;

        mat-error {
            line-height: 18px;
        }
    }

    &.mat-form-field-can-float {
        &.mat-form-field-should-float {
            .mat-form-field-label {
                transform: translateY(-17px) scale(0.75) !important;
                color: black;
            }
        }
    }

    &.mat-form-search {
        .mat-form-field-subscript-wrapper {
            margin-top: 0px;
        }
    }
}

.mat-list-item-content {
    @media screen and (max-width: 1600px) {
        padding: 0px 12px !important;
    }
}

.mat-snack-bar-container {
    height: 100%;
}

.mat-checkbox {
    &.mat-checkbox-checked,
    .mat-checkbox-indeterminate {
        .mat-checkbox-background {
            background-color: $theme-color !important;
        }
    }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $theme-color !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $theme-color !important;
}
