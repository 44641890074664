.btn {
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid $theme-color !important;
    color: $theme-color;
    box-shadow: none !important;
    transition: .1s;
    white-space: nowrap;
    min-width: 90px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1600px) {
        padding: 5px 10px;
        font-size: 13px;
        min-width: 80px;
    }

    @media screen and (max-width: 568px) {
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 3px;
        min-width: 70px;
    }

    &:hover {
        transform: translateY(-2px);
        color: $theme-color;
    }

    &.btn-primary {
        color: #ffffff;
        background-color: $theme-color !important;
    }

    &.btn-simple {
        border: 1px solid #9e9e9e !important;
        color: #444444;
        background-color: #f5f5f5 !important;
    }

    i {
        margin-right: 8px;
        font-size: 15px;

        @media screen and (max-width: 1600px) {
            font-size: 13px;
        }
    }

    .mat-badge-content {
        background: #ecf0f5;
        color: black;
        box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.25);
        left: -6px !important;
        top: -13px !important;
        height: 20px;
        width: 20px;
        line-height: 20px;
    }
    &.btn-indicator-cover{
        position: relative;
        .button-indicator{
            height: 8px;
            right: 3px;
            top: -13px;
            width: 8px;
            background: #ff0ea9;
            position: absolute;
            border-radius: 50%;
        }
    }

    // Pulse effect
    .pulse_effect {
        border-radius: 50%;
        position: absolute;
        height: 14px;
        width: 14px;
        left: -10px;
        top: -3px;
        right: 0;
        bottom: 0;
        transform: rotateX(0deg);
        &:after {
            content: "";
            border-radius: 50%;
            height: 100%;
            width: 100%;
            position: absolute;
            animation : pulse .65s ease-out;
            animation-iteration-count: infinite;
            box-shadow: 0 0px 4px 3px #00000040;
            animation-delay: 0s;
        }
    }
    
    @keyframes pulse {
        0% {
            transform :scale(0.5, 0.5);
            opacity :0;
        }
        50% {
            opacity :1;
        }
        100% {
            transform :scale(1, 1);
            opacity:0;
        }
    }
}

.fa-star{
    color: #ff9900;
    font-size: 12px;
    margin: 0px 5px;
}

.action-icon-cover {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &.icon-check-mark {
            color: #4caf50;
        }

        &.icon-cancel {
            color: #f44336;
        }

        &.icon-delete {
            color: #ce0101;
        }

        &.icon-edit {
            color: #0d8c69;
        }

        &.icon-unblock {
            color: #0d8c69;
        }

        &.icon-block {
            color: #b30000;
        }

        @media screen and (max-width: 1600px) {
            font-size: 18px;
        }

        @media screen and (max-width: 568px) {
            font-size: 16px;
        }
    }
}

.table-btn-cover {
    display: flex;
    justify-content: center;

    .btn {
        padding: 2px 4px;
        min-width: auto;
        font-size: 12px;

        @media screen and (max-width: 568px) {
            padding: 1px 3px;
            min-width: auto;
            font-size: 9px;
        }

        &:not(:last-child) {
            margin-right: 5px;

            @media screen and (max-width: 568px) {
                margin-right: 3px;
            }
        }

        &:disabled {
            touch-action: none;
            pointer-events: none;
        }

        &:hover {
            background-color: $theme-color;
            color: white;
            transform: none;
        }
    }
}

.icon-unblock {
    color: #0d8c69;
    cursor: pointer;
}