
// Single-Axis Scroller

@mixin custom-scroller($axis, $brodness: 7px, $bgcolor: $theme-color) {
    @if ($axis == "y") {
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: $brodness;
        }
        &::-webkit-scrollbar-track {
            width: $brodness;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 20px 20px;
            background-color: $bgcolor;
            opacity: 0.5;
            width: $brodness;
            &:hover {
            opacity: 1;
            }
        }
    }
    // overflow x-axis
    @if ($axis == "x") {
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            height: $brodness;
        }
        &::-webkit-scrollbar-track {
            height: $brodness;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 20px 20px;
            background-color: $bgcolor;
            height: $brodness;
            opacity: 0.5;
            &:hover {
            opacity: 1;
            }
        }
    }
}
  
//   Two-Axis Scroller
  
@mixin scroller ($brodness: 7px, $bgcolor: $theme-color) {
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
        width: $brodness;
        height:  $brodness;
    }
    &::-webkit-scrollbar-track {
        width: $brodness;
        height: $brodness;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 20px 20px;
        background-color: $bgcolor;
        opacity: 0.5;
        width: $brodness;
        &:hover {
            opacity: 1;
        }
    }
}

// Box-Shadow

@mixin box-shadow {
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}