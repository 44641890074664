* {
  outline: none;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  word-break: break-word;
  list-style: none;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: $theme-color;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: $theme-color;
}

.table-cover {
  @include box-shadow;
}

a {
  text-decoration: underline;
  color: $theme-color;
}

.breadcrumbnew {
  margin-bottom: 15px;

  h4 {
    font-size: 16px;
    margin: 0px;

    @media screen and (max-width: 1600px) {
      font-size: 14px;
    }

    span {
      cursor: pointer;
      transition: .2s;

      &:hover {
        text-decoration: underline;
        color: $theme-color;
      }
    }
  }
}

.listing-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: -14px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }

  .left-filter-child {
    @media screen and (max-width: 700px) {
      width: 100% !important;
    }
  }

  .left-side-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media screen and (max-width: 700px) {
      width: 100% !important;
    }

    .left-filter-child {
      &:not(:last-child) {
        margin-right: 10px;

        @media screen and (max-width: 700px) {
          margin-right: 0px;
        }
      }
    }
  }

  .right-side-filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 10px 0px;

    @media screen and (max-width: 700px) {
      width: 100% !important;
      padding: 0px 0px;
    }

    button {
      @media screen and (max-width: 700px) {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.primary-detail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  mat-form-field,
  app-dropdown-filter,
  .price-cover {
    width: 25%;

    &:not(:nth-child(4n)) {
      padding-right: 25px;
    }

    @media screen and (max-width: 1250px) {
      width: 33.33%;

      &:not(:nth-child(4n)) {
        padding-right: 0px;
      }

      &:not(:nth-child(3n)) {
        padding-right: 20px;
      }
    }

    @media screen and (max-width: 768px) {
      width: 50%;

      &:not(:nth-child(3n)) {
        padding-right: 0px;
      }

      &:not(:nth-child(2n)) {
        padding-right: 15px;
      }
    }

    @media screen and (max-width: 568px) {
      width: 100%;

      &:not(:nth-child(2n)) {
        padding-right: 0px;
      }
    }
  }
}

.common-filter-cover {
  form {
    mat-form-field {
      margin-bottom: 10px;
      width: 100%;
    }

    .couple-filter-cover {
      padding-top: 1px;

      h6 {
        margin: 15px 0px;
        font-size: 15px;
      }

      .couple-filter-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        mat-form-field {
          width: 50%;

          &:first-child {
            padding-right: 10px;
          }

          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.no-data-cover {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  img {
    width: 300px;
    max-width: 80%;
  }
}

.detail-cover {
  p {
    &.label {
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 5px;
    }

    &.value {
      font-size: 13px;
    }
  }
}
